<template>
  <BCard class="mt-2">
    <div class="flex justify-between items-center">
      <div class="text-black text-xl font-medium">
        Detail Order
      </div>

      <div class="flex gap-[1rem] h-[38px]">
        <BButton
          variant="primary"
          :disabled="isDisableUpdateOrder"
          @click="$bvModal.show('confirm-update-order')"
        >
          Check Update
        </BButton>

        <BDropdown
          variant="outline-primary"
          no-caret
          right
          lazy
          size="sm"
          class="order-dropdown"
        >
          <template #button-content>
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/more.svg"
              alt="more"
            >
          </template>
          <BDropdownItemButton
            class="order-dropdown-item"
            @click="handleCancelOrder"
          >
            Cancel Order
          </BDropdownItemButton>
          <BDropdownItemButton
            class="order-dropdown-item"
            :disabled="isDisableButtonPaketHilangAndPaketRusak"
            @click="handlePaketHilang"
          >
            Paket Hilang
          </BDropdownItemButton>
          <BDropdownItemButton
            class="order-dropdown-item"
            :disabled="isDisableButtonPaketHilangAndPaketRusak"
            @click="handlePaketRusak"
          >
            Paket Rusak
          </BDropdownItemButton>
          <BDropdownItemButton
            v-if="['Dikirim'].some(status => status === this.data.order_status)"
            class="order-dropdown-item"
            @click="handleDeliveredPackage"
          >
            Paket Diterima
          </BDropdownItemButton>
        </BDropdown>
      </div>
    </div>
    <BRow class="mt-2 justify-center">
      <BCol lg="11">
        <div class="text-black text-lg">
          Informasi Order
        </div>
        <BCard body-class="bg-[#ffffff] border border-[1px] rounded-lg mt-2">
          <BRow class="mb-1">
            <BCol cols="6">
              No Order
            </BCol>
            <BCol
              cols="6"
              class="font-bold text-black"
            >
              {{ data.order_no }}
            </BCol>
          </BRow>
          <BRow class="mb-1">
            <BCol cols="6">
              Tanggal Order
            </BCol>
            <BCol
              cols="6"
              class="font-bold text-black"
            >
              {{ DAY_MONTH_YEAR(data.order_date) }}
            </BCol>
          </BRow>
          <BRow class="mb-1">
            <BCol cols="6">
              Metode Pembayarann
            </BCol>
            <BCol
              cols="6"
              class="font-bold text-black"
            >
              {{ data.payment_method }}
            </BCol>
          </BRow>
          <BRow class="mb-1">
            <BCol
              cols="6"
              class="flex items-center"
            >
              Status
            </BCol>
            <BCol
              cols="6"
              class="font-bold"
            >
              <BadgeStatusOrder :status-order="data.order_status" />
            </BCol>
          </BRow>
          <BRow
            class="mb-1"
          >
            <BCol cols="6">
              No Ticket
            </BCol>
            <BCol
              cols="6"
              class="font-bold text-black cursor-pointer"
              role="buttom"
              @click="navigateToTicketDetail"
            >
              {{ noTicket }}
            </BCol>
          </BRow>
          <div class="d-flex mt-10 mb-1">
            Telah ditambahkan oleh ‘{{ data.user_fullname }}’ pada {{ FULL_DATE_TIME(data.order_date) }} WIB
          </div>
        </BCard>
      </BCol>
    </BRow>
    <BRow class="mt-2 justify-center">
      <BCol lg="11">
        <div class="text-black text-lg">
          Informasi Pengiriman
        </div>
        <BCard body-class="bg-[#ffffff] border border-[1px] rounded-lg mt-2">
          <BRow class="mb-1">
            <BCol cols="6">
              <div class="d-flex">
                <BAvatar />
                <div class="ml-1 my-auto">
                  <span class="font-bold text-black">{{ data.customer_name }}</span><br>
                  <span>(+62){{ parseInt(data.customer_phone, 10) }}</span>
                </div>
              </div>
            </BCol>
            <BCol cols="6">
              <span
                v-if="data.is_komship === 1"
                class="d-flex my-auto justify-end"
              >
                Pengiriman via <img
                  src="https://storage.googleapis.com/komerce/assets/logo/Komship.png"
                  style="margin-left:5px;"
                  alt="Komship"
                >
              </span>
              <span v-else>
                Pengiriman Non Komship
              </span>
            </BCol>
          </BRow>
          <BRow class="mb-1">
            <BCol cols="6">
              Ekspedisi
            </BCol>
            <BCol
              cols="6"
              class="font-bold d-flex"
            >
              <img
                :src="data.shipment_image_path"
                style="width: 45px"
              >
              <span
                class="my-auto"
                style="margin-left:5px"
              >
                {{ data.shipment_alias }}
              </span>
            </BCol>
          </BRow>
          <BRow class="mb-1">
            <BCol cols="6">
              No Resi
            </BCol>
            <BCol
              cols="6"
              class="font-bold d-flex items-center"
            >
              {{ data.airway_bill }}
              <img
                v-if="data.airway_bill"
                src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
                class="copy-resi cursor-pointer ml-1"
                @click.prevent="copyResi(data.airway_bill)"
              >
              <span v-if="data.airway_bill === null">-</span>
            </BCol>
          </BRow>
          <BRow class="mb-1">
            <BCol cols="6">
              Detail Alamat
            </BCol>
            <BCol
              cols="6"
              class="font-bold"
            >
              {{ data.customer_address }}
            </BCol>
          </BRow>
          <BRow class="mt-3">
            <BCol class="d-flex justify-content-end">
              <BButton
                class="mr-1"
                :disabled="data.airway_bill === null"
                :variant="data.airway_bill === null ? 'secondary' : 'primary'"
                @click="$bvModal.show('print-label')"
              >
                Print Label
              </BButton>
              <BButton
                v-if="trackingShowCondition(data.order_status)"
                variant="outline-primary"
                @click="getTicketTrip()"
              >
                <BSpinner
                  v-if="loading"
                  small
                  variant="primary"
                />
                Lacak resi
              </BButton>
            </BCol>
          </BRow>
        </BCard>
      </BCol>
    </BRow>
    <BRow class="justify-center">
      <BCol lg="11">
        <div class="text-black text-lg">
          Informasi Penjualan
        </div>
        <BCard body-class="pl-0 pr-0">
          <BTable
            responsive
            :fields="fields"
            :items="items"
          >
            <template #cell(no)="value">
              {{ value.index + 1 }}
            </template>
            <template #cell(product_name)="value">
              <div class="flex items-center">
                <img
                  :src="value.item.product_image !== null ? value.item.product_image : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                  alt="Komerce"
                  class="w-[45px]"
                >
                <div class=" ml-[5px]">
                  <div class="text-black">
                    {{ value.item.product_name }}
                  </div>
                  <div class="text-[#E31A1A]">
                    {{ value.item.variant_name }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(price)="value">
              {{ IDR(value.item.price) }}
            </template>
            <template #cell(subtotal)="value">
              {{ IDR(value.item.price * value.item.qty) }}
            </template>
          </BTable>
          <hr>
          <BRow
            v-if="data.order_notes !== null"
            class="mt-3 justify-center"
          >
            <BCol lg="12">
              <span class="text-black text-xl">Catatan Order</span>
              <BFormTextarea
                v-model="data.order_notes"
                disabled
                no-resize
                class="mt-1"
              />
            </BCol>
          </BRow>
          <BRow class="mt-3 justify-end">
            <BCol lg="3" />
            <BCol lg="5">
              Total Harga Produk
            </BCol>
            <BCol
              lg="3"
              class="text-right"
            >
              {{ IDR(data.subtotal) }}
            </BCol>
          </BRow>
          <BRow class="mt-1 justify-end">
            <BCol lg="3" />
            <BCol lg="5">
              Ongkos Kirim
            </BCol>
            <BCol
              lg="3"
              class="text-right"
            >
              {{ IDR(data.shipping_cost) }}
            </BCol>
          </BRow>
          <BRow class="mt-1 justify-end">
            <BCol lg="3" />
            <BCol lg="5">
              Potongan Harga
            </BCol>
            <BCol
              lg="3"
              class="text-right"
            >
              {{ IDR(data.discount) }}
            </BCol>
          </BRow>
          <BRow class="mt-1 justify-end">
            <BCol lg="3" />
            <BCol lg="5">
              Biaya Lain
            </BCol>
            <BCol
              lg="3"
              class="text-right"
            >
              {{ IDR(data.additional_cost) }}
            </BCol>
          </BRow>
          <BRow class="mt-1 justify-end">
            <BCol lg="3" />
            <BCol lg="8">
              <hr><span />
            </BCol>
          </BRow>
          <BRow
            class="mt-1 justify-end"
            :class="data.order_status === 'Retur' ? 'line-through' : ''"
          >
            <BCol lg="3" />
            <BCol
              lg="5"
              class="font-bold text-xl"
            >
              Total Pembayaran ({{ data.payment_method }}) :
            </BCol>
            <BCol
              lg="3"
              class="text-right font-bold text-primary text-xl"
            >
              {{ IDR(data.grandtotal) }}
            </BCol>
          </BRow>
          <BRow
            v-if="data.grandtotal !== data.old_grandtotal"
            class="mt-1 justify-end"
          >
            <BCol lg="3" />
            <BCol
              lg="5"
              class="font-bold text-sm text-primary"
            >
              {{ data.notes }}
            </BCol>
          </BRow>
          <BRow class="mt-1 justify-end">
            <BCol lg="3" />
            <BCol lg="5">
              <hr><span />
            </BCol>
            <BCol
              lg="3"
              class="d-flex justify-end"
            >
              <BButton
                v-b-toggle="'collapse-1'"
                class="buttonCollapse px-0"
                variant="none"
                size="sm"
              >
                <span class="when-open">Tutup <b-icon-chevron-up /></span>
                <span class="when-closed">Buka <b-icon-chevron-down /></span>
              </BButton>
            </BCol>
          </BRow>
          <BCollapse id="collapse-1">
            <BRow class="mt-1 justify-end">
              <BCol lg="3" />
              <BCol
                lg="5"
              >
                {{ data.payment_method }} ({{ data.service_fee_to }}% sudah termasuk PPN)
              </BCol>
              <BCol
                lg="3"
                class="text-right"
              >
                - {{ IDR(data.service_fee) }}
              </BCol>
            </BRow>
            <BRow class="mt-1 justify-end">
              <BCol lg="3" />
              <BCol
                lg="5"
              >
                Ongkos Kirim (dipotong Cashback {{ data.cashback_to }}%)
              </BCol>
              <BCol
                lg="3"
                class="text-right text-[#F95031]"
              >
                - {{ IDR(data.shipping_cost - data.shipping_cashback) }}
              </BCol>
            </BRow>
            <BRow
              v-if="data.order_status === 'Retur'"
              class="mt-1 justify-end"
            >
              <BCol lg="3" />
              <BCol
                lg="5"
              >
                Ongkos Kirim Pengembalian (diskon {{ data.percentage_cost_retur }}%)
              </BCol>
              <BCol
                lg="3"
                class="text-right #E31A1A"
              >
                {{ IDR(data.shipping_retur) }}
              </BCol>
            </BRow>
            <BRow
              v-if="data.is_insurance === 1"
              class="mt-1 justify-end"
            >
              <BCol lg="3" />
              <BCol lg="5">
                <div class="d-flex">
                  <span class="mr-1">Biaya Asuransi</span>
                  <img
                    id="popover-asuransi"
                    src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                    alt=""
                  >
                  <b-popover
                    triggers="hover focus"
                    target="popover-asuransi"
                    placement="topright"
                  >
                    <a
                      href="https://bantuan.komerce.id/id/article/asuransi-pengiriman-ekspedisi-di-komship-17pow0b/?bust=1678871763794"
                      target="_blank"
                      class="text-[#08A0F7]"
                    ><u>Klik disini</u></a> buat detail tarifnya.
                  </b-popover>
                </div>
              </BCol>
              <BCol
                lg="3"
                class="text-right text-[#F95031]"
              >
                <span>- {{ IDR(data.insurance_fee) }}</span>
              </BCol>
            </BRow>
          </BCollapse>
          <BRow class="mt-1 justify-end">
            <BCol lg="3" />
            <BCol
              lg="5"
              class="font-bold"
            >
              Penghasilan bersih yang kamu dapatkan
            </BCol>
            <BCol
              v-if="statusNetProfit === '-'"
              lg="3"
              class="text-right text-[#E31A1A] font-bold"
            >
              {{ IDR(data.net_profit) }}
            </BCol>
            <BCol
              v-else
              lg="3"
              class="text-right text-[#E31A1A] font-bold"
            >
              {{ IDR(data.net_profit) }}
            </BCol>
          </BRow>
        </BCard>
      </BCol>
    </BRow>
    <ModalPrint :data="data" />
    <ModalTracking
      :awb="data.airway_bill"
      :data="data"
    />
    <ModalConfirm
      id="confirm-cancel-order"
      ref="confirm-cancel-order"
      title="Cancel Order"
      message="Kamu yakin mau membatalkan orderan resi ini"
      label-confirm-button="Cancel Order"
      label-cancel-button="Kembali"
      :loading-confirm-button="loading"
      @on-click-cancel-button="$bvModal.hide('confirm-cancel-order')"
      @on-click-confirm-button="handleConfirmCancelOrder"
    />
    <ModalConfirm
      id="confirm-update-order"
      ref="confirm-update-order"
      title="Check Update"
      message="konfirmasi dan pemberitahuan waktu tunggu 1-3 menit untuk proses update status tersebut"
      :loading-confirm-button="loading"
      @on-click-cancel-button="$bvModal.hide('confirm-update-order')"
      @on-click-confirm-button="handleUpdateOrder"
    />
    <ModalConfirm
      id="confirm-change-loss-order"
      ref="confirm-change-loss-order"
      title="Mengubah Paket ke Hilang"
      :message="moreThan14Days(data.ticket.last_update)"
      label-confirm-button="Ubah Status Paket"
      label-cancel-button="Kembali"
      :loading-confirm-button="loading"
      @on-click-cancel-button="$bvModal.hide('confirm-change-loss-order')"
      @on-click-confirm-button="handleChnageStatusOrder('package_lost', 'confirm-change-loss-order')"
    />
    <ModalConfirm
      id="confirm-change-damaged-order"
      ref="confirm-change-damaged-order"
      title="Mengubah Paket ke Rusak"
      message="Orderan ini akan diubah statusnya menjadi <span class='font-bold'>Paket Rusak</span>. Dampaknya akan membuat / mengupdate tiket dengan resi tersebut ke status “Perlu tindak lanjut seller” untuk proses claim Nett Profit."
      label-confirm-button="Ubah Status Paket"
      label-cancel-button="Kembali"
      :loading-confirm-button="loading"
      @on-click-cancel-button="$bvModal.hide('confirm-change-damaged-order')"
      @on-click-confirm-button="handleChnageStatusOrder('package_damaged', 'confirm-change-damaged-order')"
    />

    <ModalChangeDelivered ref="change-delivered" :order-id="data.order_id"></ModalChangeDelivered>
  </BCard>
</template>

<script>
import { DAY_MONTH_YEAR, FULL_DATE_TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import ModalPrint from './ModalPrint.vue'
import ModalTracking from './ModalTracking.vue'
import BadgeStatusOrder from './BadgeStatusOrder.vue'
import ModalChangeDelivered from './component/ModalChangeDelivered.vue'

export default {
  components: {
    ModalPrint, ModalTracking, ModalConfirm, BadgeStatusOrder, ModalChangeDelivered,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    statusOrder: {
      type: String,
      default: '',
    },
    statusNetProfit: {
      type: String,
      default: '',
    },
  },
  emits: ['search-order'],
  data() {
    return {
      DAY_MONTH_YEAR,
      FULL_DATE_TIME,
      IDR,
      dataTracking: {},
      loading: false,
    }
  },
  computed: {
    isOrderReturDiterima() {
      return this.data.order_status === 'Retur' && this.data.is_retur === 2
    },
    isOrderReturDalamPerjalanan() {
      return this.data.order_status === 'Retur' && this.data.is_retur === 1
    },
    isDisableCancelOrder() {
      return this.loading || this.isOrderReturDiterima || this.isOrderReturDalamPerjalanan || ['Diterima', 'Batal'].some(status => status === this.data.order_status)
    },
    isDisableButtonPaketHilangAndPaketRusak() {
      return this.loading || ['Paket Hilang', 'Paket Rusak'].some(ticketType => ticketType === (this.data.ticket && this.data.ticket.ticket_type_name))
    },
    isDisableUpdateOrder() {
      return this.loading || this.isOrderReturDiterima || ['Diterima', 'Batal', 'Diajukan'].some(status => status === this.data.order_status)
    },
    noTicket() {
      return this.data.is_problem === 1 ? this.data.ticket.ticket_no : '-'
    },
  },
  methods: {
    async getTicketTrip() {
      this.$bvModal.show('tracking-resi')
    },
    async copyResi(resi) {
      await navigator.clipboard.writeText(resi)
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        didOpen: toast => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: 'success',
        title: '<span class="text-success">Success copy to clipboard</span>',
        showCloseButton: true,
      })
      // eslint-disable-next-line no-empty
    },
    printLabel() {
      this.$$bvModal.show('print-label')
    },
    trackingShowCondition(status) {
      if (status === 'Diajukan' || status === 'Dipacking') {
        return false
      }
      return true
    },
    handleConfirmCancelOrder() {
      this.loading = true

      const url = `/v1/admin/order/cancel/${this.data.order_id}`

      komshipAxiosIns.post(url)
        .then(async () => {
          await this.$emit('search-order')

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Order berhasil dibatalkan',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: error.response.data.message || 'Gagal cancel order',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$bvModal.hide('confirm-cancel-order')

          this.loading = false
        })
    },
    handleUpdateOrder() {
      this.loading = true

      const body = {
        order_no: this.data.order_no,
      }

      const url = '/v1/admin/order/check-update'

      komshipAxiosIns.post(url, body)
        .then(async () => {
          await this.$emit('search-order')

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Check update berhasil',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: error.response.data.message || 'Gagal update order',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$bvModal.hide('confirm-update-order')

          this.loading = false
        })
    },
    navigateToTicketDetail() {
      const url = this.$router.resolve({
        path: `/tiket/detail/${this.data.ticket.id}`,
        params: { data_tiket: this.data.ticket.id },
      })
      window.open(url.href, '_blank')
    },
    handlePaketHilang() {
      if (['Diajukan', 'Batal', 'Dipacking', 'Diterima'].some(status => status === this.data.order_status) || this.isOrderReturDiterima) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Tidak bisa Update Order dari orderan yang statusnya  "Dibuat", "Dibatalkan", "Dipacking", "Diterima", dan "Retur diterima"',
            variant: 'danger',
          },
        }, 3000)
      } else if (this.data.order_status === 'Dikirim' || this.isOrderReturDalamPerjalanan) {
        this.$bvModal.show('confirm-change-loss-order')
      }
    },
    handlePaketRusak() {
      if (['Diajukan', 'Batal', 'Dipacking', 'Diterima'].some(status => status === this.data.order_status)) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Tidak bisa Update Order dari orderan yang statusnya  "Dibuat", "Dibatalkan"/"Dipacking", "Diterima"',
            variant: 'danger',
          },
        }, 3000)
      } else if (this.data.order_status === 'Dikirim' || this.isOrderReturDalamPerjalanan || this.isOrderReturDiterima) {
        this.$bvModal.show('confirm-change-damaged-order')
      }
    },
    handleCancelOrder() {
      if (['Diterima', 'Batal'].some(status => status === this.data.order_status) || this.isOrderReturDiterima || this.isOrderReturDalamPerjalanan) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Tidak bisa Cancel Order dari orderan yang statusnya "Diterima", "Dibatalkan", dan "Retur"',
            variant: 'danger',
          },
        }, 3000)
      } else {
        this.$bvModal.show('confirm-cancel-order')
      }
    },
    handleChnageStatusOrder(nextStatus, modalId) {
      this.loading = true

      const url = '/v1/admin/order/manual-transaction'

      const body = {
        order_id: this.data.order_id,
        order_status: nextStatus,
      }

      komshipAxiosIns.post(url, body)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Berhasil update status order',
              variant: 'success',
            },
          })
        })
        .then(() => {
          this.$emit('search-order')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: error.response.data.message || 'Gagal update status order',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$bvModal.hide(modalId)

          this.loading = false
        })
    },
    handleDeliveredPackage() {
      this.$refs['change-delivered'].show()
    },
    moreThan14Days(lastDate) {
      const date = new Date(lastDate)
      const now = new Date()
      const diffTime = Math.abs(now - date)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (lastDate !== null && diffDays > 14) {
        return `
          <div class="mb-[24px]">Orderan ini akan diubah statusnya menjadi “Paket Hilang”, menyelesaikan tiket dan menambah saldo user secara langsung</div>
          <div class="flex items-center gap-[12px] bg-[#FCEBBE] font-semibold px-[20px] py-[16px] text-start rounded-[4px]">
            <img src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg" width="24" />
            Paket Sudah Stuck Lebih dari 14 Hari
          </div>
        `
      }
      return `
        Orderan ini akan diubah statusnya menjadi <span class="font-bold">Paket Hilang</span>.
        Dampaknya akan membuat / mengupdate tiket dengan resi tersebut ke status “Perlu tindak lanjut seller” untuk proses claim Nett Profit.
      `
    },
  },
}
</script>

<style scoped lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #C2C2C2;
  border-color: #C2C2C2 !important
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #C2C2C2 !important;
  border-color: #C2C2C2 !important
}

.order-dropdown ::v-deep .dropdown-toggle {
  padding: 0.486rem !important;
}

.order-dropdown-item ::v-deep .dropdown-item {
  width: 100%;
  padding: 10px;
}

.order-dropdown-item ::v-deep button:focus {
  outline: none !important;
}

.order-dropdown ::v-deep .dropdown-menu {
  min-width: 200px;
}
</style>
