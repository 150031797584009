<template>
  <div :class="['font-bold w-fit p-[10px] rounded', badgeColor]">
    {{ statusOrderLabel }}
  </div>
</template>

<script>
export default ({
  props: {
    statusOrder: {
      type: String,
      required: true,
    },
  },

  computed: {
    statusOrderLabel() {
      let label = ''

      switch (this.statusOrder) {
        case 'Diajukan':
          label = 'Order dibuat'
          break

        case 'Batal':
          label = 'Order Dibatalkan'
          break

        case 'Hilang':
          label = 'Paket Hilang'
          break

        case 'Rusak':
          label = 'Paket Rusak'
          break

        default:
          label = this.statusOrder
          break
      }

      return label
    },

    badgeColor() {
      return this.statusOrder === 'Dikirim' ? 'bg-[#FCEBBE] text-[#FBA63C]' : 'text-[#E31A1A] bg-[#FFEDED]'
    },
  },
})
</script>
